// Helper function to remove added error when user click on input


export function validateField(value) {
    const specialCaracter = new RegExp(/[.,\/#!£$%\^&\*;:{}=\[\]\"\-_`~()]/);
    return specialCaracter.test(value)
}

function validatePassword(value) {

    const specialCaractersRegex = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    let password_length = value.length >= 8;
    let special_caracter = specialCaractersRegex.test(value);
    let one_number = /\d/.test(value);
    let one_upper = /(?=.*[A-Z].*)/.test(value);

    if(password_length && special_caracter && one_number && one_upper) {
        return false
    }

    return true
}

export function validateEmail(email) {
    const emailRegex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    console.log("Email " + email);
    return email == null || emailRegex.test(email) == false;
}


// Main function checking each field and adding error message
export function validate(fields) {
    let errorInputs = [];
    let ignoreKeys = ["additional_owner", "apartment", "company_name", "email", "phone", "redirect_url", "password", "confirmPassword"];
    if (('email' in fields) && validateEmail(fields['email']) && !errorInputs.includes('email')) {
        errorInputs.push('email');
    }
    if(('phone' in fields) && !fields['phone'] && !errorInputs.includes('phone')) {
        errorInputs.push('phone');    
    }

    if(('password' in fields) && !fields['password']) {
        errorInputs.push('password');
    }

    if(('confirmPassword' in fields) && !fields['confirmPassword']) {
        errorInputs.push('confirmPassword');
    }

    for (const [key, value] of Object.entries(fields)) {
        if ((value == null || value == "" || validateField(value)) && !ignoreKeys.includes(key)) {
            console.log(key + " " + validateField(value));
            errorInputs.push(key);
        }
    }

    console.log(errorInputs);
    for (let error of errorInputs) {
        let errorField = document.getElementById(error);
        //errorField.set("");
        console.log(errorField);
        if (errorInputs.includes(error)) {
            let label = errorField.parentElement;
            if (!childExist(error)) {
                let msg = `<div class="required" id="errorText">
            <div class="form-icon">
            <svg viewBox = "0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z" />
<path d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z" />
            </svg>
            </div>
            This is a required field
            </div>`;
            msg = new DOMParser().parseFromString(msg, 'text/html');
                label.appendChild(msg.body);
                errorField.value = "";
            }
        }
    }

    return errorInputs.length > 0;
}


export function removeField(e) {
    console.log("Hit event");
    if (e.target.nodeName == "INPUT") {
        let target = e.target; // input

        console.log(target);
        if (target) {
            let elem = target.id;
            if (childExist(elem)) {
                console.log("elem " + elem);
                removeErr(elem);
            }
        }
    }
}

function removeErr(elem) {
    let test = document.querySelector(`label[for=${elem}] #errorText`);
    test.remove();
}

// Check child element exist
function childExist(elem) {
    let target = document.querySelector(`label[for=${elem}] #errorText`);
    return target != null;
}